import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link as ReactLink } from 'react-router-dom';
import { Box, Button, IconButton, Typography, Divider, AppBar, Toolbar, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../assets/nurturance-therapy-hummer.png';

function Header() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const goToHomePage = () => {
    navigate('/');
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const Icon = ({ src, alt, height }) => {
    return <img src={src} alt={alt} height={height} />;
  };

  const menuItems = (
    <Box>
      <Typography variant="h6" sx={{ padding: 2 }}>Menu</Typography>
      <Divider />
      <Button color="inherit" component={ReactLink} to="/" onClick={toggleDrawer(false)} fullWidth>
        Home
      </Button>
      <Button color="inherit" component={ReactLink} to="/services" onClick={toggleDrawer(false)} fullWidth>
        Services
      </Button>
      <Button color="inherit" component={ReactLink} to="/about" onClick={toggleDrawer(false)} fullWidth>
        About Me
      </Button>
      <Button color="inherit" component={ReactLink} to="/contact" onClick={toggleDrawer(false)} variant="outlined" fullWidth>
        Contact
      </Button>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
        <IconButton color="inherit" onClick={() => window.open("https://www.instagram.com/nurturancetherapy/")}>
          <InstagramIcon />
        </IconButton>
        <IconButton color="inherit" onClick={() => window.open("https://www.facebook.com/lacey.joseph.1656")}>
          <FacebookIcon />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar sx={{ height: 140, justifyContent: 'space-between' }}>
          <Box sx={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} onClick={goToHomePage}>
            <Icon src={logo} alt="Logo" height="110"/>
          </Box>

          {/* Hamburger Menu Icon */}
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', md: 'none' } }}>
            <MenuIcon />
          </IconButton>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'flex-end', width: '50%' }}>
            <Typography variant="body2" sx={{ mb: 2, fontSize: 15 }}>
              <b>Lacey Joseph, MA LPC</b> │ <a href="mailto:laceytjoseph@gmail.com">laceytjoseph@gmail.com</a>
            </Typography>
            <Divider sx={{ width: '100%', mb: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', mt: 1.5 }}>
              {/* Desktop Buttons */}
              <Button color="inherit" component={ReactLink} to="/">Home</Button>
              <Button color="inherit" component={ReactLink} to="/services">Services</Button>
              <Button color="inherit" component={ReactLink} to="/about">About Me</Button>
              <Button color="inherit" component={ReactLink} to="/contact" variant="outlined">
                Contact
              </Button>
              {/* Social Media Icons */}
              <IconButton color="inherit" onClick={() => window.open("https://www.instagram.com/nurturancetherapy/")}>
                <InstagramIcon />
              </IconButton>
              <IconButton color="inherit" onClick={() => window.open("https://www.facebook.com/lacey.joseph.1656")}>
                <FacebookIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Drawer for Mobile Menu */}
          <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
            {menuItems}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;