import { React } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css';
import theme from './theme'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
    <Router>
      {/* <div className="d-flex flex-column min-vh-100"> */}
        <Header />
        <main className="flex-grow-1" sx={{ overflowX: 'hidden' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      {/* </div> */}
        <Footer />
    </Router>
    </CssBaseline>
</ThemeProvider>
  );
}

export default App;
