import React from 'react';
import { Box, Typography, IconButton, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {

  return (
    <Box 
      component="footer" 
      sx={{ 
        py: 3, 
        bgcolor: '#ECE4D7', 
        textAlign: 'center', 
        width: '100%', 
        position: 'relative', 
        bottom: 0
      }}
    >
      <Container maxWidth="sm">
        <Box sx={{ mb: 1 }}>
          <IconButton
            aria-label="Email"
            href="mailto:laceytjoseph@gmail.com"
          >
            <EmailIcon fontSize="large" />
          </IconButton>
        </Box>
        <Typography variant="body2" color="text.secondary">
          &copy; Nurturance Therapy, LLC {new Date().getFullYear()}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;