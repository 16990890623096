import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    backgroundColor: '#DAA544',
    color: "white",
    opacity: .85,
    border: `1px solid white`,
    boxShadow: 'none',
    transition: 'all 0.3s ease-in-out',
    width: '400px', // Set your desired width
    height: '60px', // Set your desired height
    fontSize: '1.1rem', // Adjust font size if needed
    '&:hover': {
      backgroundColor: "#DAA544",
      color: 'white',
      boxShadow: 'none',
      opacity: 1,
      border: `1px solid #DAA544`
    }
  }));

export default StyledButton;