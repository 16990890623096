import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid2,
  Paper,
  Container
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Lavender from '../assets/lavender.png';
import ContactMeTitle from '../assets/contact-me.png';
import { Icon } from '../components/Helpers';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to a server
    console.log('Form submitted:', formData);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Icon src={ContactMeTitle} alt="contact-me" height="50" />
        </Box>
        <Grid2 container spacing={3}>
          <Grid2 item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, position: 'relative' }}>
              <Typography variant="h5" gutterBottom>
                Schedule a consult and begin your healing journey
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Send Message
                </Button>
              </form>
              <Box sx={{ position: 'absolute', bottom: 8, right: 50 }}>
                <Icon src={Lavender} alt="lav" height="130" />
              </Box>
            </Paper>
          </Grid2>
          <Grid2 item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom>
                Contact Information
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <EmailIcon sx={{ mr: 2 }} />
                <Typography>laceytjoseph@gmail.com</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PhoneIcon sx={{ mr: 2 }} />
                <Typography>+1 (720) 432-8656</Typography>
              </Box>
            </Paper>
          </Grid2>
        </Grid2>
      </Box>
    </Container>
  );
};

export default Contact;