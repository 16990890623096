import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router';
import { Box, Typography, Paper, Grid, Divider, Snackbar, Avatar, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from '../assets/nurturance-therapy.png';
import sunflower from '../assets/sunflower.png';
import StyledButton from '../components/StyledButton';
import LJOnTop from '../assets/_DSC5717.jpg';
import AutoCarousel from '../components/Carousel';
import { Icon, handleClose } from '../components/Helpers';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Home = () => {
  const [open, setOpen] = useState(true);
  const closeHandler = handleClose(setOpen);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeHandler}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose(setOpen)}
        message="Currently accepting Colorado-based clients for therapy, and out-of-state clients for therapeutic coaching!"
        action={action}
        sx={{ opacity: .7 }}
        ContentProps={{
          sx: {
            display: 'flex',
            justifyContent: 'space-between', // Aligns message and action
            alignItems: 'center', // Vertically centers items
            width: { xs: '90%', sm: '60%' },
            backgroundColor: '#CDACA1',
            color: 'black',
            borderRadius: '8px',
            fontSize: 18,
          }
        }}
      />
      <Box sx={{ flexGrow: 1, p: { xs: 2, md: 4 } }}>
        <Grid container spacing={4}>
          {/* Left section */}
          <Grid item xs={12} md={6}> {/* Use item instead of size */}
            <Paper elevation={12} sx={{ p: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h4" sx={{ mr: 3 }}>
                  Welcome to
                </Typography>
                <Icon src={logo} alt="Logo" height="90" />
              </Box>
              <Box sx={{ justifyContent: 'center' }}>
                <Typography variant='h5'>
                  <Divider sx={{ pt: 2 }}>Lacey Joseph, MA LPC</Divider>
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                  <Avatar
                    src={LJOnTop}
                    alt="Lacey Joseph"
                    sx={{ width: 275, height: 275, mt: 2 }}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Right section */}
          <Grid item xs={12} md={6}> {/* Use item instead of size */}
            <Paper elevation={12} sx={{ p: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 4 }}>
                <Typography variant='h4' gutterBottom component="div" sx={{ pb: 2, fontStyle: 'italic' }}>
                  Come as you are
                </Typography>
                <Icon src={sunflower} alt="sunflower" height="120" />
              </Box>
              <Typography variant="body1" gutterBottom component="div" sx={{ pb: 2 }}>
                Navigating the complexities of life can be challenging.
              </Typography>
              <Typography variant='body1' gutterBottom component='div' sx={{ pb: 2 }}>
                Whether you’re dealing with anxiety, depression, or relationship struggles, I am here to offer compassionate support without judgment.
              </Typography>
              <Typography variant="body1" gutterBottom component="div" sx={{ pb: 2 }}>
                Together, we can explore new pathways to cultivate calmness, discover joy, and foster thriving relationships.
              </Typography>
              <Divider variant='middle' gutterBottom />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <StyledButton variant="contained" to="/contact" component={ReactLink}>
                  Contact for Free Consult
                </StyledButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>

      </Box>

      <AutoCarousel />

      {/* Accordion Section */}
      <Grid container spacing={2} sx={{ backgroundColor: '#89999A', minHeight: '58vh', height: 'auto', p: 3 }}>
        {/* Left Accordion */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>No Surprises Act & Good Faith Estimates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='h6' gutterBottom>
                Private Pay and the No Surprises Act: Good Faith Estimate
              </Typography>
              <Typography variant='body2' gutterBottom>
                You have the right to receive a “Good Faith Estimate” explaining how much your medical care will cost
                Under the law, health care providers need to give patients who don’t have insurance or who are not using insurance an estimate of the bill for medical items and services.
              </Typography>
              <Typography variant='body2' gutterBottom>
                You have the right to receive a Good Faith Estimate for the total expected cost of any non-emergency items or services. This includes related costs like medical tests, prescription drugs, equipment, and hospital fees.
              </Typography>
              <Typography variant='body2' gutterBottom>
                Make sure your health care provider gives you a Good Faith Estimate in writing at least 1 business day before your medical service or item.You can also ask your health care provider, and any other provider you choose, for a Good Faith Estimate before you schedule an item or service.
              </Typography>
              <Typography variant='body2' gutterBottom>
                If you receive a bill that is at least $400 more than your Good Faith Estimate, you can dispute the bill.
              </Typography>
              <Typography variant='body2' gutterBottom>
                Make sure to save a copy or picture of your Good Faith Estimate.
              </Typography>
              <Typography variant='body2' gutterBottom>
                For questions or more information about your right to a Good Faith Estimate, visit www.cms.gov/nosurprises or call the Colorado Division of Insurance at 303-894-7490 or 1-800-930-3745.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src="https://res.cloudinary.com/df4rizvgx/image/upload/v1714330861/mountains/DSC_6107_uyucta.jpg"
            alt="Description"
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Grid>
      </Grid>

    </Box>
  );
};

export default Home;

