import React, { useEffect, useState } from 'react';
import { Box, Fade, Typography, Divider, Link, Paper, Grid2, List, ListItem, ListItemText, ListItemIcon, Snackbar } from '@mui/material';
import { keyframes } from '@mui/system';
import { Link as ReactLink } from 'react-router';
import SpaIcon from '@mui/icons-material/Spa';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import LooksIcon from '@mui/icons-material/Looks';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CloudIcon from '@mui/icons-material/Cloud';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import StyledButton from '../components/StyledButton';
import IT from '../assets/individual.png';
import TC from '../assets/coaching.png';
import RT from '../assets/relationship.png';
import { Icon, handleClose } from '../components/Helpers';

const Section = ({ title, children, backgroundColor, isVisible, iconHeight }) => (
  <Fade in={isVisible} timeout={1000}>
    <Box
      sx={{
        height: '59vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Stack title and content vertically
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: backgroundColor,
        animation: isVisible ? `${slideIn} 1s ease-out` : 'none',
        p: 4, // Add some padding for better spacing
        pt: 12,
        transition: 'all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1)', // Smoother easing
        '&:hover': {
          transform: 'scale(1.015)', // Slightly smaller scale for subtlety
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // Optional: adds a subtle shadow on hover
        },
      }}
    >
      <Icon src={title} height={iconHeight} />
      <Box sx={{ maxWidth: '600px', textAlign: 'center' }}>
        {children}
      </Box>
    </Box>
  </Fade>
);

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Services = () => {
  const [visibleSection, setVisibleSection] = useState(0);
  const [open, setOpen] = useState(true);
  const closeHandler = handleClose(setOpen);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeHandler}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setVisibleSection((prev) => (prev < 2 ? prev + 1 : prev));
    }, 1500); // Change section every 1.5 seconds

    return () => clearInterval(timer);
  }, []);

  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' }, // Stack sections on small screens
      width: '100%',
      gap: 2 // Add space between sections
    }}
  >
    <Section
      backgroundColor="#9FAC9D"
      title={IT}
      isVisible={visibleSection >= 0}
      iconHeight={125}
    >
      <Divider />
      <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
        Individual therapy provides a safe space for personal growth and healing.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We will work together to identify your goals and develop strategies to achieve them.
      </Typography>
    </Section>

    <Section
      backgroundColor="#CDACA1"
      title={RT}
      isVisible={visibleSection >= 1}
      iconHeight={110}
    >
      <Divider />
      <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
        All relationship styles welcome - LGBTQIAP+ and non-traditional relationships embraced.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
        Partner with <Link color="inherit" href="https://www.denvercouplescenter.com/">Denver Couples Center</Link>.
      </Typography>
    </Section>

    <Section
      backgroundColor="#89999A"
      title={TC}
      isVisible={visibleSection >= 2}
      iconHeight={110}
    >
      <Divider />
      <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
        Life coaching focuses on personal development and achieving your life goals.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Together, we will create a roadmap to success tailored to your unique aspirations.
      </Typography>
    </Section>
  </Box>

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose(setOpen)}
        message="Currently accepting Colorado-based clients for therapy, and out-of-state clients for therapeutic coaching!"
        action={action}
        sx={{ opacity: .7 }}
        ContentProps={{
          sx: {
            display: 'flex',
            justifyContent: 'space-between', // Aligns message and action
            alignItems: 'center', // Vertically centers items
            width: { xs: '90%', sm: '60%' },
            backgroundColor: '#CDACA1',
            color: 'black',
            borderRadius: '8px',
            fontSize: 18,
          }
        }}
      />
      <Box sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
        <Section backgroundColor="#9FAC9D" title={IT} isVisible={visibleSection >= 0} iconHeight={125}>
          <Divider />
          <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
            Individual therapy provides a safe space for personal growth and healing.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We will work together to identify your goals and develop strategies to achieve them.
          </Typography>
        </Section>
        <Section backgroundColor="#CDACA1" title={RT} isVisible={visibleSection >= 1} iconHeight={110}>
          <Divider />
          <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
            All relationship styles welcome - LGBTQIAP+ and non-traditional relationships embraced.
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
            Partner with <Link color="inherit" href="https://www.denvercouplescenter.com/">Denver Couples Center</Link>.
          </Typography>
        </Section>
        <Section backgroundColor="#89999A" title={TC} isVisible={visibleSection >= 2} iconHeight={110}>
          <Divider />
          <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
            Life coaching focuses on personal development and achieving your life goals.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Together, we will create a roadmap to success tailored to your unique aspirations.
          </Typography>
        </Section>
      </Box>
      <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
        <Paper elevation={12} sx={{ width: '80%', p: 4, mb: 2, mt: 2, justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
          <Grid2
            container
            spacing={4}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Grid2
              item
              xs={12}
              md={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h5" align="center" gutterBottom>
                Office Hours
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Tuesday - Friday: <b>Virtual</b>
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Saturday: <b>In-office (limited availability)</b>
              </Typography>
            </Grid2>
            <Grid2
              item
              xs={12}
              md={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Grid2 item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: { xs: 2, sm: 3 } }}>
                  <StyledButton
                    variant="contained"
                    to="/contact"
                    component={ReactLink}
                    sx={{
                      width: { xs: '100%', sm: 'auto' }, // Full width on small screens, auto on larger
                      padding: { xs: 2, sm: 3 }, // Responsive padding
                      fontSize: { xs: '1rem', sm: '1.25rem' } // Responsive font size
                    }}
                  >
                    Schedule your free consult
                  </StyledButton>
                </Box>
              </Grid2>
            </Grid2>
            <Grid2
              item
              xs={12}
              md={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Grid2 item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h5" align="center">
                  Pricing
                </Typography>
                <List sx={{ '& .MuiListItem-root': { py: 0.1 } }}>
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="$170 per 50-minute session" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="$190 per 50-minute session" />
                  </ListItem>
                </List>
              </Grid2>
            </Grid2>
          </Grid2>
          <Divider />
        </Paper>
      </Box>

      <Box sx={{ display: 'flex', backgroundColor: '#DDC8B7', height: '60vh' }}>
        <Grid2 container>
          <Grid2 item xs={12} md={6} sx={{ height: '100%' }}>
            <img
              src="https://res.cloudinary.com/df4rizvgx/image/upload/v1714330285/landscapes/_DSC4879_kheb6h.jpg"
              alt="Description"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Grid2>
          <Grid2 item xs={12} md={6} sx={{ p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'right' }}>
            <Typography variant="h4" gutterBottom>
              Specialties
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <PsychologyIcon />
                </ListItemIcon>
                <ListItemText primary="Gestalt" primaryTypographyProps={{ fontSize: '1.2rem' }} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <SpaIcon />
                </ListItemIcon>
                <ListItemText primary="Mindfulness" primaryTypographyProps={{ fontSize: '1.2rem' }} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LooksIcon />
                </ListItemIcon>
                <ListItemText primary="LGBTQIAP+" primaryTypographyProps={{ fontSize: '1.2rem' }} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FavoriteIcon />
                </ListItemIcon>
                <ListItemText primary="Non-Traditional Relationships" primaryTypographyProps={{ fontSize: '1.2rem' }} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CloudIcon />
                </ListItemIcon>
                <ListItemText primary="Anxiety and Depression" primaryTypographyProps={{ fontSize: '1.2rem' }} />
              </ListItem>
            </List>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
}

export default Services;