import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Avatar, Snackbar } from '@mui/material';
import profilePicture from '../assets/_DSC5841.jpeg';
import Leaf from '../assets/leaf.png';
import Daisy from '../assets/daisy.png';
import AboutMeTitle from '../assets/about-me.png';
import { Icon, handleClose } from '../components/Helpers';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AboutMe = () => {
  const [open, setOpen] = useState(true);

  const closeHandler = handleClose(setOpen);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeHandler}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box sx={{ padding: { xs: 2, sm: 3 } }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose(setOpen)}
        message="Currently accepting Colorado-based clients for therapy, and out-of-state clients for therapeutic coaching!"
        action={action}
        sx={{ opacity: .7 }}
        ContentProps={{
          sx: {
            display: 'flex',
            justifyContent: 'space-between', // Aligns message and action
            alignItems: 'center', // Vertically centers items
            width: { xs: '90%', sm: '60%' },
            backgroundColor: '#CDACA1',
            color: 'black',
            borderRadius: '8px',
            fontSize: 18,
          }
        }}
      />

  <Grid container spacing={2}>
    <Grid item xs={12} lg={4}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          height: '100%', 
          position: 'relative' 
        }}
      >
        <Avatar
          src={profilePicture}
          alt="Lacey Joseph"
          sx={{ width: { xs: 200, md: 250, lg: 350 }, height: { xs: 200, md: 250, lg: 350 }, mb: 2 }} // Responsive size
        />
        <Box sx={{ position: 'absolute', top: { xs: 20, md: 30 }, left: { xs: 20, md: 80 } }}>
          <Icon src={Daisy} alt="daisy" height="80" /> {/* Adjusted height for smaller screens */}
        </Box>
        <Typography variant="h5" component="h1" align="center">
          Lacey Joseph (she/they)
        </Typography>
      </Paper>
    </Grid>

    <Grid item xs={12} lg={8}>
      <Paper elevation={3} sx={{ p: 4, height: '100%', position: 'relative' }}>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Icon src={AboutMeTitle} alt="about-me" height="40" /> {/* Adjusted height for smaller screens */}
          </Box>
          <Typography variant="body1" gutterBottom>
            Hello and welcome! I'm so glad you're here.
          </Typography>
          <Typography variant="body1" gutterBottom>
            I'm excited and equipped to meet you where you are, learn your story, and collaboratively guide you on this journey towards growth and wholeness. I strive to integrate my knowledge and practice of meditation with Gestalt and Neurosculpting philosophies, along with my love of nature, art, and music into our therapeutic container.
          </Typography>
          <Typography variant="body1" gutterBottom>
            As a strong advocate of self-care, you'll often find me swimming, playing with animals, meditating, or enjoying a silly TV show like <i>Broad City</i>, <i>Pen15</i>, or <i>Sex Education</i>.
          </Typography>
          <Typography variant="body1" gutterBottom>
            In our tender, empathetic space, you can feel confident, comfortable, and intentional as you embark on this vulnerable exploration. With a strong social justice lens, I offer safety for any and all identities to share their experiences, find common ground, allyship, and healing. I am here for you.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Black Lives Matter.
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', bottom: { xs: 10, md: 0 }, right: { xs: 10, md: 30 } }}>
          <Icon src={Leaf} alt="leaf" height="80" /> {/* Adjusted height for smaller screens */}
        </Box>
      </Paper>
    </Grid>
  </Grid>
</Box>
  );
};

export default AboutMe;